<template>
    <div>
        <dl class="grid max-w-screen-lg gap-6 mx-auto text-white sm:grid-cols-4">
            <div class="bg-olive sm:bg-transparent p-5 sm:p-0 rounded-xl flex flex-col items-center justify-center">
                <LabelNumber :number="100" prefix="" suffix="%" label="Satisfaction Clients"></LabelNumber>
            </div>
            <div class="bg-olive sm:bg-transparent p-5 sm:p-0 rounded-xl flex flex-col items-center justify-center">
                <LabelNumber :number="40" prefix="+" suffix="" label="Mariages Capturés"></LabelNumber>
            </div>
            <div class="bg-olive sm:bg-transparent p-5 sm:p-0 rounded-xl flex flex-col items-center justify-center">
                <LabelNumber :number="20000" prefix="+" suffix="" label="Photos Délivrées"></LabelNumber>
            </div>
            <div class="bg-olive sm:bg-transparent p-5 sm:p-0 rounded-xl flex flex-col items-center justify-center">
                <LabelNumber :number="6" prefix="" suffix="" label="Années d'expériences"></LabelNumber>
            </div>
        </dl>
    </div>
</template>

<script>
import LabelNumber from '@/Components/Statistics/LabelNumber.vue';

export default {
    components: {
        LabelNumber,
    },
    data() {
        return {
            clientSatisfaction: 0
        };
    },
    mounted() {
        this.incrementCounter();
    },
    methods: {
        incrementCounter() {
            let target = 100;
            let current = 0;
            let increment = target / 100;
            let interval = setInterval(() => {
                current += increment;
                if (current >= target) {
                    current = target;
                    clearInterval(interval);
                }
                this.clientSatisfaction = Math.round(current);
            }, 20); // La vitesse de l'incrémentation peut être ajustée ici
        }
    }
};
</script>

<style scoped>
/* Ajoutez ici vos styles pour les animations ou la mise en forme si nécessaire */
</style>