<template>
    <div class="flex items-center justify-center" :class="{ 'gap-2': xs, 'gap-3': !xs, 'text-white': white }">
        <fa icon="bolt" class="text-red-500" :class="{ 'w-3': xs, 'text-white': white }"></fa>
        <p class="text-red-500 font-bold" :class="{ 'text-sm': xs, 'text-white': white }">Réponse en 24 heures</p>
    </div>
</template>

<script>
export default {
    props: {
        xs: {
            type: Boolean,
            default: false
        },
        white: {
            type: Boolean,
            default: false
        }
    }
}
</script>
