<template>
    <h1 class="text-5xl sm:text-huge font-playfair-medium text-olive leading-small">
        {{ titleText }}
    </h1>
</template>

<script>
export default {
    name: 'Title',
    props: {
        titleText: {
            type: String,
            default: 'TITRE H1'
        }
    }
};
</script>

<style scoped>
/* Les styles spécifiques peuvent être ajoutés ici si nécessaire */
</style>