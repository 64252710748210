<template>
    <section class="max-w-screen-xl mx-auto px-4 lg:px-6 py-8 lg:py-16 rounded-xl my-8 relative">
        <div class="max-w-screen-md mx-auto">
            <p class="uppercase mb-4 text-center">Témoignages</p>
            <TitleH2 class="text-3xl lg:text-4xl text-center" titleText="Des histoires réelles, clients satisfaits">
            </TitleH2>
        </div>

        <div class="my-16 lg:my-28"></div>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <BentoTestimonial v-for="(testimonial, index) in visibleTestimonials" :key="index"
                :title="testimonial.title" :description="testimonial.description" :image="testimonial.image"
                :identity="testimonial.identity" :date="testimonial.date" :note="testimonial.note"></BentoTestimonial>
        </div>

        <div v-if="hasMoreTestimonials"
            class="bg-gradient-to-b from-transparent to-linen h-80 md:h-20 lg:h-64 w-full absolute bottom-8 md:bottom-16 lg:bottom-0 backdrop-blur-sm flex items-center justify-center">
            <div>
                <Button textButton="Lire plus d'avis" dark @click="loadMoreTestimonials"></Button>
            </div>
        </div>
    </section>
</template>


<script>
import TitleH2 from '@/Components/Typography/Headings/TitleH2.vue';
import Button from '@/Components/Buttons/Button.vue';
import BentoTestimonial from '@/Components/Bento/Testimonial.vue'

export default {
    name: 'Testimonial',
    components: {
        TitleH2,
        Button,
        BentoTestimonial
    },
    data() {
        return {
            initialTestimonialsCount: 6,
            loadMoreCount: 3,
            currentTestimonialsCount: 6,
            testimonials: [
                {
                    title: "Un photographe en or !",
                    description: "Sacha est un grand professionnel ! Il est à l’écoute de nos attentes et il a su parfaitement retranscrire par ses photos l’ambiance familiale de notre mariage. Nous sommes très heureux d’avoir de si beaux souvenirs de cette merveilleuse journée ! Tous les invités sont également ravis. Encore merci !",
                    image: require('@/assets/images/slider/0.jpg'),
                    identity: "Marie & Quentin",
                    date: "Marié le 13/04/2024",
                    note: 5
                },
                {
                    title: "Prestation mariage",
                    description: "Sacha est un très bon photographe,il a su capter les bons moments de notre mariage.sa prestation était parfaite. Une personne avec de grandes qualités humaines, accessible, réactifs et à l'écoute.Je le remercie du fond du cœur d'avoir immortaliser ce moment inoubliable de notre vie.",
                    image: require('@/assets/images/slider/0.jpg'),
                    identity: "Sofia & Lofti",
                    date: "Marié le 10/04/2024",
                    note: 5
                },
                {
                    title: "Photographe de mariage",
                    description: "Sacha nous a offert de magnifiques souvenirs de notre mariage grâce à ses photos. Discret mais toujours au même endroit, un super coup d'oeil, il a su nous (et nos invités) mettre à l'aise pour nos photos de couple et de groupe. Les photos sont naturelles avec un joli côté artistique.",
                    image: require('@/assets/images/slider/0.jpg'),
                    identity: "Laure & Clément",
                    date: "Marié le 10/04/2024",
                    note: 5
                },
                {
                    title: "Prestation mariage",
                    description: "Un grand merci, Sacha, pour avoir immortalisé ces moments magiques, riches en émotions ! Des photos très réussies, savoir capter \"Les instants\" est un travail difficile...vous l'avez exercé à merveille...Bravo !",
                    image: require('@/assets/images/slider/0.jpg'),
                    identity: "Laure & François",
                    date: "Marié le 28/10/2023",
                    note: 5
                },
                {
                    title: "Photo reportage mariage",
                    description: "Un grand merci, Sacha, pour avoir immortalisé ces moments magiques...",
                    image: require('@/assets/images/slider/0.jpg'),
                    identity: "Benoît & Carine",
                    date: "Marié le 23/09/2023",
                    note: 5
                },
                {
                    title: "Super photographe !",
                    description: "Nous sommes ravis d'avoir fait appel à Sacha pour immortaliser notre mariage ! Les photos sont magnifiques et pleine d'authenticité. Sacha est très professionnel, sympathique, avec une excellente communication. Nos invités ont également beaucoup apprécié son travail et sa présence discrète. Nous le recommandons les yeux fermés !",
                    image: require('@/assets/images/slider/0.jpg'),
                    identity: "Virginie & Geoffrey",
                    date: "Marié le 02/09/2023",
                    note: 5
                },
                {
                    title: "Un photographe au top !",
                    description: "Nous recommandons Sacha les yeux fermés...",
                    image: require('@/assets/images/slider/0.jpg'),
                    identity: "Anne & Cédric",
                    date: "Marié le 05/08/2023",
                    note: 5
                },
                {
                    title: "Un super partenaire pour le mariage pour de supers photos",
                    description: "PareilArt a photographié notre mariage...",
                    image: require('@/assets/images/slider/0.jpg'),
                    identity: "Laura & Mathieu",
                    date: "Marié le 29/07/2023",
                    note: 5
                },
                {
                    title: "Photographe au top !",
                    description: "Sacha est une personne très professionnelle et sympathique...",
                    image: require('@/assets/images/slider/0.jpg'),
                    identity: "Anaïs & Anthony",
                    date: "Marié le 22/07/2023",
                    note: 5
                },
                {
                    title: "Un très bon photographe",
                    description: "Nous avons choisi Sacha pour réaliser les photos de notre mariage...",
                    image: require('@/assets/images/slider/0.jpg'),
                    identity: "Alexandra & Jordan",
                    date: "Marié le 10/06/2023",
                    note: 5
                },
                {
                    title: "Des souvenirs à vie",
                    description: "Nous avons eu la chance d'avoir Sacha à nos côtés pour notre mariage, il a su nous conseiller, nous guider et surtout a respecté nos envies. Nous avons récupéré nos clichés rapidement et avec une immense émotion nous avons redécouvert notre mariage. Certaines photos sont dignes d'image du cinéma, elles sont juste incroyables. de vrais instants volés. Si c'était à refaire nous le referions 100fois. Merci infiniment Sacha !!",
                    image: require('@/assets/images/slider/0.jpg'),
                    identity: "Manon & Amaury",
                    date: "Marié le 25/02/2023",
                    note: 5
                },
                {
                    title: "Au delà de parfait",
                    description: "Sacha est une personne très agréable, discrète et à l'écoute. Il fait preuve d'un grand professionnalisme et d'adaptation. Je recommande à 3000%. Les tarifs sont très abordables.Merci encore pour tout.",
                    image: require('@/assets/images/slider/0.jpg'),
                    identity: "Rabia & Vincent",
                    date: "Marié le 22/10/2022",
                    note: 5
                },
                {
                    title: "Une superbe rencontre",
                    description: "Nous avons contacté Sacha afin qu'il réalise nos photographies de mariage. Les premiers contacts ont été très naturels ; Sacha a su être à notre écoute et nous a accompagné tout du long ! Nous avons découvert nos photos il y a quelques semaines et nous avons été ravi du résultat. Un énorme merci à ce jeune photographe qui pose un regard moderne à la photographie !",
                    image: require('@/assets/images/slider/0.jpg'),
                    identity: "Anélia & Grégoire",
                    date: "Marié le 03/09/2022",
                    note: 5
                }
            ],
            isMobile: false,
        };
    },
    mounted() {
        this.checkIfMobile();
    },

    computed: {
        visibleTestimonials() {
            if (this.isMobile) {
                return this.testimonials.slice(0, this.currentTestimonialsCount);
            } else {
                return this.testimonials.slice(0, this.currentTestimonialsCount);
            }
        },
        hasMoreTestimonials() {
            return this.currentTestimonialsCount < this.testimonials.length;
        }
    },

    methods: {
        checkIfMobile() {
            this.isMobile = window.innerWidth <= 768; // Adjust 768 as per your mobile breakpoint
            if (this.isMobile) {
                this.currentTestimonialsCount = 3;
            }
        },
        loadMoreTestimonials() {
            console.log('a');
            this.currentTestimonialsCount = Math.min(this.currentTestimonialsCount + this.loadMoreCount, this.testimonials.length);
        }
    }
}
</script>