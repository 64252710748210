<template>
    <h2 :class="titleClasses" class="text-xl sm:text-2xl font-playfair-medium text-olive sm:leading-small leading-6">{{
        titleText }}</h2>
</template>

<script>
export default {
    name: 'Title',
    props: {
        titleText: {
            type: String,
            default: 'TITRE H2'
        },
        dark: {
            type: Boolean,
            default: false
        },
        xs: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        titleClasses() {
            return [
                this.dark ? 'text-white' : 'text-olive',
                {
                    'md:text-2xl': this.xs,
                    'md:text-4xl': !this.xs,
                }
            ];
        }
    }
};
</script>

<style scoped>
/* Les styles spécifiques peuvent être ajoutés ici si nécessaire */
</style>
