<template>
    <button :class="buttonClasses" class="rounded-full text-center font-bold transition-all duration-300 text-nowrap">
        {{ textButton }}
    </button>
</template>

<script>
export default {
    name: 'Button',
    props: {
        textButton: {
            type: String,
            default: 'TEXT BUTTON'
        },
        dark: {
            type: Boolean,
            default: false
        },
        xs: {
            type: Boolean,
            default: false
        },
        wFull: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        buttonClasses() {
            // Définition des classes de base
            let classes = [
                this.dark ? 'bg-black text-white hover:bg-olive hover:text-white hover:scale-105' : 'bg-white text-black hover:bg-olive hover:text-white hover:scale-105'
            ];

            // Ajout des classes spécifiques si xs est true
            if (this.xs) {
                classes.push('px-4', 'py-2', 'text-sm');
            } else {
                classes.push('px-8', 'py-4');
            }

            // Ajout de la classe w-full si wFull est true
            if (this.wFull) {
                classes.push('w-full');
            }

            return classes;
        }
    }
};
</script>

<style scoped>
/* Aucun style spécifique nécessaire ici pour les classes générées dynamiquement */
</style>
