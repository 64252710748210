<template>
    <button :class="buttonClasses" class="px-8 py-4 bg-linen rounded-full text-black text-center font-bold hover:scale-105 hover:bg-olive hover:text-white transition-all duration-300">{{ textButton }}</button>
</template>

<script>
export default {
    name: 'darkButton',
    props: {
        textButton: {
            type: String,
            default: 'TEXT BUTTON'
        },
        fullWidth: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        buttonClasses() {
            return [
                {'w-full': this.fullWidth} // Ajoute la classe 'w-full' si fullWidth est vrai
            ];
        }
    }
};
</script>

<style scoped>
/* Les styles spécifiques peuvent être ajoutés ici si nécessaire */
</style>
