<template>
    <div :class="{ 'sm:flex-row-reverse flex-col-reverse': flexReverse }" class="flex flex-col-reverse sm:flex-row items-center gap-8 md:gap-32">
        <div class="w-full md:w-1/2">
            <div class="max-w-screen-md mx-auto px-4 md:px-0">
                <p class="uppercase mb-4 text-center">{{ subTitle }}</p>
                <h2 class="text-4xl font-playfair-medium text-olive leading-small text-center">{{ title }}</h2>

                <div class="my-10"></div>
                <slot name="content"></slot>
                <p class="text-center">{{ content }}</p>
            </div>
        </div>
        <div class="w-full md:w-1/2">
            <img :src="image" alt="Image description" class="mx-auto md:ml-auto md:mr-0">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        subTitle: String,
        title: String,
        content: String,
        image: String,
        flexReverse: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped>
/* Styles spécifiques peuvent être ajoutés ici si nécessaire */
</style>
