<template>
    <div class="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
        <!-- Pricing Card -->
        <div class="w-full  bg-white rounded-xl">
            <img :src="require('@/assets/images/prices/price1.jpg')" class="w-full h-full rounded-xl">
        </div>
        <!-- Pricing Card -->
        <div
            class="w-full flex flex-col p-6 mx-auto max-w-lg text-center justify-center text-gray-900 bg-white rounded-xl border border-gray-100 shadow ">
            <div class="justify-center items-baseline mb-8">
                <h3 class="mb-4 text-3xl font-bold">À partir de</h3>
                <span class="mr-2 text-5xl font-extrabold">620€</span>
            </div>

            <!-- List -->
            <ul role="list" class="mb-8 space-y-5 text-left">
                <li class="flex items-center space-x-3">
                    <!-- Icon -->
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span><b>4 heures</b> de prise de vues</span>
                </li>

                <li class="flex items-center space-x-3">
                    <!-- Icon -->
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span><b>350 photographies remises minimum</b></span>
                </li>

                <li class="flex items-center space-x-3">
                    <!-- Icon -->
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span><b>2 Rendez-vous</b> préparatoires</span>
                </li>

                <li class="flex items-center space-x-3">
                    <!-- Icon -->
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>Traitement, retouche et développement des photographies</span>
                </li>
                <li class="flex items-center space-x-3">
                    <!-- Icon -->
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>Remise des photographies sur une galerie privée à identifiant unique</span>
                </li>
                <li class="flex items-center space-x-3">
                    <!-- Icon -->
                    <svg class="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor"
                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span>Faire-part d'invitation à la galerie offert</span>
                </li>
            </ul>

            <Button textButton="Réservez" dark @click="emitReservationEvent"></Button>
        </div>
        <!-- Pricing Card -->
        <div class="w-full  bg-white rounded-xl">
            <img :src="require('@/assets/images/prices/price2.jpg')" class="w-full h-full rounded-xl">
        </div>
    </div>
</template>

<script>
import Button from '@/Components/Buttons/Button.vue';

export default {
    name: 'PricingCards',
    components: {
        Button,

    },
    data() {
        return {

        };
    },
    mounted() {
    },

    computed: {
    },

    methods: {
        emitReservationEvent() {
            this.$emit('reservation');
        }
    }
}
</script>
