<template>
    <div class="flex flex-col-reverse sm:flex-row bg-white rounded-2xl w-full">
        <div class="w-full p-8 lg:w-1/2 space-y-12">
            <template v-if="isLoading">
                <div class="flex items-center justify-center w-full h-full">
                    <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">

                        <svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32"
                            xmlns='http://www.w3.org/2000/svg'>
                            <path clip-rule='evenodd'
                                d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                                fill='currentColor' fill-rule='evenodd' />
                        </svg>


                        <div>En cours d'envoi ...</div>
                    </div>
                </div>
            </template>
            <template v-else>
                <template v-if="!successMessage">
                    <TitleH2 titleText="Contactez-moi pour réaliser le reportage de vos rêves"></TitleH2>
                    <form class="max-w-md mx-auto text-left" @submit.prevent="submitForm">
                        <div class="mb-5">
                            <label for="identity" class="mb-3 block text-base font-medium text-[#07074D]">
                                Prénom & Nom
                            </label>
                            <input v-model="form.name" required type="text" name="identity" id="identity"
                                placeholder="Pierre Dupont"
                                class="w-full appearance-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-linen focus:shadow-md" />
                        </div>

                        <div class="-mx-3 flex flex-wrap">
                            <div class="w-full px-3 sm:w-1/2">
                                <div class="mb-5">
                                    <label for="email" class="mb-3 block text-base font-medium text-[#07074D]">
                                        E-mail
                                    </label>
                                    <input v-model="form.email" required type="email" name="email" id="email"
                                        placeholder="pierre@gmail.com"
                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-linen focus:shadow-md" />
                                </div>
                            </div>
                            <div class="w-full px-3 sm:w-1/2">
                                <div class="mb-5">
                                    <label for="phone" class="mb-3 block text-base font-medium text-[#07074D]">
                                        Téléphone
                                    </label>
                                    <input v-model="form.phone" required type="phone" name="phone" id="phone"
                                        placeholder="01 23 45 67 89"
                                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-linen focus:shadow-md" />
                                </div>
                            </div>
                        </div>

                        <div class="mb-5">
                            <label for="date" class="mb-3 block text-base font-medium text-[#07074D]">
                                Date de l'événement
                            </label>
                            <input v-model="form.eventDate" required type="date" name="date" id="date"
                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-linen focus:shadow-md" />

                        </div>

                        <div class="mb-5">
                            <label for="message" class="mb-3 block text-base font-medium text-[#07074D]">
                                Message
                            </label>
                            <textarea v-model="form.message" name="floating_message" id="floating_message"
                                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-linen focus:shadow-md h-40"
                                placeholder=" " required>
                            </textarea>
                        </div>

                        <Button textButton="Envoyez" dark></Button>
                    </form>
                </template>
                <template v-else>
                    <p>{{ successMessage }}</p>
                </template>
            </template>
        </div>
        <div class="block sm:w-1/2 bg-cover bg-center rounded-t-2xl sm:rounded-tr-2xl sm:rounded-br-2xl h-32 sm:h-auto"
            :style="backgroundImage">
        </div>
    </div>
</template>


<script>
import imageUrl from '@/assets/images/contact.jpg';
import TitleH2 from '@/Components/Typography/Headings/TitleH2.vue';
import Button from '@/Components/Buttons/Button.vue';

import axios from 'axios';

export default {
    components: {
        TitleH2,
        Button
    },
    data() {
        return {
            backgroundImage: `background-image: url(${imageUrl})`,
            form: {
                name: '',
                email: '',
                phone: '',
                eventDate: '',
                message: 'Nous sommes heureux de vous avoir trouvé et nous aimerions avoir plus d\'informations concernant vos services. Merci d\'avance !'
            },
            successMessage: '',
            isLoading: false
        };
    },
    methods: {
        async submitForm() {
            this.isLoading = true;
            try {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/send`, this.form);
                if (response.status === 200) {
                    this.successMessage = 'Votre message a été envoyé avec succès.';
                    this.form = { name: '', email: '', phone: '', eventDate: '', message: '' };
                    this.$router.push({ name: 'success' });
                }
            } catch (error) {
                console.error('Erreur lors de l\'envoi du formulaire:', error);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>
