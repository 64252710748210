<template>
    <div class="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10 z-50 backdrop-blur-md z-50"
        @click="handleClick">
        <div @click.stop class="w-5/6 sm:w-4/6 h-4/5 sm:h-full overflow-auto">
            <slot name="content"></slot>
        </div>
        <fa @click="closeModal" icon="xmark"
            class="absolute top-0 right-0 border-2 rounded-full w-4 h-4 p-1 border-white text-white m-10 hover:scale-110 cursor-pointer">
        </fa>
    </div>
</template>

<script>
export default {
    name: 'Modal',
    props: {
        title: String,
        content: String
    },
    methods: {
        closeModal() {
            this.$emit('close'); // Émet un événement pour fermer la modal
        },
        handleClick(event) {
            this.closeModal();

        }
    }
};
</script>

<style scoped>
/* Styles spécifiques pour la modal peuvent être ajoutés ici */
</style>
