<template>
  <header class="relative mx-8 sm:mx-0 mb-8">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-4 items-center md:h-75vh mt-8 sm:mt-0">
      <div class="md:w-1/2 md:ml-auto md:mr-16 space-y-6">
        <TitleH1 class="text-center md:text-left" titleText="Réalisons le mariage de vos rêves" />
        <Paragraph class="text-center md:text-left ">
          Expérience photographique unique & capture de vos moments spéciaux pour vous.
        </Paragraph>
        <div class="flex flex-col md:flex-row items-center justify-center md:justify-start gap-5">
          <Button textButton="Réservez maintenant" dark @click="openModal('contactForm')" class="mb-3 md:mb-0" />
          <VideoButton textButton="Témoignage" :videoSource="require('@/assets/videos/video-mariage.mp4')"
            @click="openModal('videoTestimonial')" />
        </div>
        <ReponseExpress />
      </div>
      <div class="flex items-center justify-center md:justify-end md:h-full overflow-hidden">
        <Slider :images="images" class="h-full" />
      </div>
    </div>


    <!-- Modal Testimonial -->
    <Transition name="fade">
      <Modal v-if="modalOpen === 'videoTestimonial'" @close="closeModal">
        <template #content>
          <div class="flex flex-col gap-5 h-full justify-center">
            <video autoplay loop class="rounded-lg">
              <source src="@/assets/videos/interview.mp4" type="video/mp4">
              Votre navigateur ne supporte pas la lecture vidéo.
            </video>
            <div class="w-full">
              <LigthButton textButton="Contactez-moi" @click="openModal('contactForm')"></LigthButton>
            </div>
          </div>
        </template>
      </Modal>
    </Transition>

    <!-- Modal Contact -->
    <Transition name="fade">
      <Modal v-if="modalOpen === 'contactForm'" @close="closeModal">
        <template #content>
          <ContactForm></ContactForm>
        </template>
      </Modal>
    </Transition>
  </header>

  <section class="sm:bg-olive w-4/5 md:max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6 rounded-xl my-8"
    style="background-color:hsla(71,18%,22%,1);
background-image:
radial-gradient(at 26% 11%, hsla(70,21%,29%,1) 0px, transparent 50%),
radial-gradient(at 16% 51%, hsla(71,9%,22%,1) 0px, transparent 50%),
radial-gradient(at 36% 31%, hsla(71,28%,33%,1) 0px, transparent 50%),
radial-gradient(at 3% 24%, hsla(71,19%,17%,1) 0px, transparent 50%),
radial-gradient(at 77% 87%, hsla(71,29%,34%,1) 0px, transparent 50%);">
    <SocialProofBanner />
  </section>

  <section class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6 rounded-xl my-8">
    <div class="max-w-screen-md mx-auto">
      <p class="uppercase mb-4">Découvrez</p>
      <TitleH2 titleText="Capturez votre amour avec le photographe qui vous comprend"></TitleH2>
    </div>

    <div class="my-20"></div>

    <SectionDetail sub-title="Les rencontres" title="Une prestation sur-mesure"
      :image="require('@/assets/images/decoration/3.png')" flexReverse>
      <template #content>
        Votre journée de mariage est un concentré d'émotions, ponctuée d'événements qui la rendront inoubliable. Pour
        nous assurer que chaque instant soit parfaitement orchestré, nous nous rencontrerons à deux reprises pour
        préparer votre grand jour. Ces rendez-vous nous permettront de personnaliser chaque détail, de capturer les
        moments essentiels et de prévoir à l'avance pour garantir une expérience fluide et harmonieuse. Mon objectif est
        de vous offrir des souvenirs intemporels qui reflètent la beauté et l'authenticité de votre amour.
      </template>
    </SectionDetail>

    <div class="my-20"></div>

    <SectionDetail sub-title="Le jour J" title="Reportage photographique sur-mesure"
      :image="require('@/assets/images/decoration/1.png')">

      <template #content>
        Chaque mariage est unique, tout comme votre histoire. Je m'engage à capturer chaque moment précieux de votre
        journée spéciale avec une approche personnalisée et sur-mesure. Chaque détail, chaque émotion sera immortalisé
        de manière authentique et artistique, reflétant votre amour et votre unicité. Avec une sensibilité particulière
        pour les moments spontanés et les instants magiques, je vous offre un reportage photographique qui va au-delà
        des images figées pour raconter votre histoire d'une manière intemporelle et captivante.
      </template>
    </SectionDetail>

    <div class="my-20"></div>

    <SectionDetail sub-title="L'après" title="Capturer votre amour avec le photographe qui vous comprend"
      :image="require('@/assets/images/decoration/2.png')" flexReverse>

      <template #content>
        Votre mariage a été une journée pleine d'émotions et de moments spéciaux. Maintenant que vous avez vécu chaque
        instant avec intensité, il est temps de revivre ces souvenirs à travers les photographies. Chaque sourire,
        chaque larme de joie, et chaque détail qui a rendu votre journée unique, capturés par mes soins. Vous pourrez
        visionner l'intégralité des photographies de votre mariage, immortalisant ainsi cette journée exceptionnelle.
      </template>
    </SectionDetail>
  </section>

  <section class="bg-deep-burgundy max-w-screen-xl sm:mx-auto px-4 lg:px-6 py-8 lg:py-16 rounded-xl mx-8 my-8 relative">
    <img :src="require('@/assets/images/banner/banner.png')"
      class="absolute inset-0 w-full h-full opacity-40 sm:opacity-100 object-cover object-right sm:object-center rounded-xl"
      alt="Banner">
    <div class="relative z-10 text-center">
      <div class="space-y-4 lg:space-y-8">
        <TitleH2 class="text-3xl lg:text-4xl"
          titleText="Laissez moi figer vos instants précieux. De l'imaginaire à la réalité." dark></TitleH2>
        <Button textButton="Réservez maintenant" @click="openModal('contactForm')" class="mx-auto"></Button>
      </div>
    </div>
  </section>


  <SectionTestimonial></SectionTestimonial>

  <section class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6 rounded-xl my-8">
    <div class="max-w-screen-md mx-auto">
      <p class="uppercase mb-4 text-center">Expérience</p>
      <TitleH2 class="text-3xl lg:text-4xl text-center"
        titleText="Mon savoir-faire, vos moments gravés pour l'éternité">
      </TitleH2>
    </div>

    <div class="my-20"></div>

    <SectionPricingCards @reservation="handleReservation"></SectionPricingCards>
  </section>

  <section class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6 rounded-xl my-8">
    <ContactForm></ContactForm>
  </section>

  <DemandExpress />

</template>

<script>
import TitleH1 from '@/Components/Typography/Headings/TitleH1.vue';
import TitleH2 from '@/Components/Typography/Headings/TitleH2.vue';

import Paragraph from '@/Components/Typography/Body/Paragraph.vue';
import Button from '@/Components/Buttons/Button.vue';
import LigthButton from '@/Components/Buttons/LigthButton.vue';
import VideoButton from '@/Components/Buttons/VideoButton.vue';
import ReponseExpress from '@/Components/Section/ReponseExpress.vue';
import Modal from '@/Components/Modals/Modal.vue';
import Slider from '@/Components/Swiper/Slider.vue';
import SocialProofBanner from '@/Components/SocialProofs/Banner.vue';

import SectionDetail from '@/Components/Section/Detail.vue';
import SectionTestimonial from '@/Components/Section/Testimonial/Testimonial.vue';
import SectionPricingCards from '@/Components/Section/Price/PrincingCards.vue';

import DemandExpress from '@/Components/Section/PopUp/DemandExpress.vue';

import ContactForm from '@/Components/Form/ContactForm.vue';


export default {
  name: 'HomeView',
  components: {
    TitleH1,
    TitleH2,
    Paragraph,
    Button,
    LigthButton,
    VideoButton,
    Modal,
    Slider,
    SocialProofBanner,
    SectionDetail,
    SectionTestimonial,
    ContactForm,
    SectionPricingCards,
    ReponseExpress,
    DemandExpress,
  },
  data() {
    return {
      modalOpen: false,
      images: [
        require('@/assets/images/slider/0.jpg'),
        require('@/assets/images/slider/1.jpg'),
        require('@/assets/images/slider/2.jpg'),
        require('@/assets/images/slider/3.jpg'),
        require('@/assets/images/slider/4.jpg'),
        require('@/assets/images/slider/5.jpg'),
        require('@/assets/images/slider/6.jpg'),
        require('@/assets/images/slider/7.jpg'),
        require('@/assets/images/slider/8.jpg'),
        require('@/assets/images/slider/9.jpg'),
        require('@/assets/images/slider/10.jpg'),
        require('@/assets/images/slider/11.jpg'),
        require('@/assets/images/slider/12.jpg'),
      ],
    };
  },
  mounted() {
  },

  computed: {
  },

  methods: {
    openModal(modalName) {
      this.modalOpen = modalName;
    },
    closeModal() {
      this.modalOpen = false;
    },

    handleReservation() {
      this.openModal('contactForm');
    }
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
