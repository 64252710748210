<template>
    <div class="relative">
        <button @click="handleButtonClick"
            class="px-8 py-4 w-full justify-center bg-black rounded-full text-white text-center font-bold flex items-center gap-5 hover:scale-105 hover:bg-olive hover:text-white transition-all duration-300">
            <div class="z-10 relative flex items-center gap-5">
                <fa icon="play"></fa>
                {{ textButton }}
            </div>

            <video autoplay muted loop class="absolute inset-0 w-full h-full object-cover z-0 rounded-full opacity-70">
                <source :src="videoSource" type="video/mp4">
                Votre navigateur ne supporte pas la lecture vidéo.
            </video>
        </button>
    </div>
</template>

<script>
export default {
    name: 'VideoButton',
    props: {
        textButton: {
            type: String,
            default: 'TEXT BUTTON'
        },
        videoSource: {
            type: String,
            required: true
        }
    },
    methods: {
        handleButtonClick() {
            this.$emit('button-clicked'); // Émet un événement 'button-clicked'
        }
    }
};
</script>

<style scoped>
/* Les styles spécifiques peuvent être ajoutés ici si nécessaire */
</style>
