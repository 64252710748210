<template>
    <div class="bg-white text-left p-8 rounded-3xl justify-between flex flex-col gap-3">
        <div class="space-y-3">
            <h3 class="font-bold text-xl">{{ title }}</h3>
            <ul>
                <li class="text-amber-400">
                    <fa v-for="i in 5" :key="i" :icon="i <= note ? ['fa', 'star'] : ['far', 'star']"></fa>
                </li>
            </ul>
            <p>{{ description }}</p>
        </div>
        <div class="flex flex-row gap-5">
            <div>
                <img :src="image" class="aspect-square w-10 object-cover rounded-full">
            </div>
            <div class="text-left">
                <p class="font-bold">{{ identity }}</p>
                <p class="text-xs">{{ date }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String,
            required: true
        },
        image: {
            type: String,
            required: true
        },
        identity: {
            type: String,
            required: true
        },
        date: {
            type: String,
            required: true
        },
        note: {
            type: Number,
            required: true
        }
    }
}
</script>
