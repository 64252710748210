<template>
    <div>
        <dt class="mb-2 text-3xl md:text-4xl font-playfair font-extrabold">{{ formattedNumber }}</dt>
        <dd class="text-white font-inter font-bold">{{ label }}</dd>
    </div>
</template>


<script>
export default {
    props: {
        number: {
            type: Number,
            default: 0
        },
        prefix: {
            type: String,
            default: ''
        },
        suffix: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            displayNumber: 0
        };
    },
    computed: {
        formattedNumber() {
            return `${this.prefix}${this.formatNumberWithSpaces(this.displayNumber)}${this.suffix}`;
        }
    },
    mounted() {
        this.incrementCounter();
    },
    methods: {
        incrementCounter() {
            let target = this.number;
            let current = 0;
            let increment = target / 100;
            let interval = setInterval(() => {
                current += increment;
                if (current >= target) {
                    current = target;
                    clearInterval(interval);
                }
                this.displayNumber = Math.round(current);
            }, 20); // La vitesse de l'incrémentation peut être ajustée ici
        },
        formatNumberWithSpaces(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        }
    }
};
</script>

<style scoped>
/* Ajoutez ici vos styles pour les animations ou la mise en forme si nécessaire */
</style>
