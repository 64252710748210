<template>
    <Transition name="fade">
        <section v-if="showPopup" class="fixed bottom-10 right-5 z-40 shadow-2xl rounded-lg max-w-xs">
            <fa icon="circle-xmark"
                class="absolute right-2 top-2 text-white text-2xl hover:scale-110 hover:text-linen transition-all duration-150 cursor-pointer shadow-2xl z-40"
                @click="showPopup = false">
            </fa>

            <div class="relative flex items-center justify-center ">
                <div class="absolute top-0 bottom-0 w-full flex flex-col items-center justify-center z-10">
                    <TitleH2 titleText="Demande express" dark xs></TitleH2>
                    <ReponseExpress xs white />
                </div>
                <div class="absolute top-0 left-0 w-full h-full bg-black opacity-40 rounded-t-xl"></div>

                <img :src="require('@/assets/images/banner-mariage.gif')" class="rounded-t-lg w-full"
                    style="z-index: -1;">
                <div class="w-full h-full bg-black"></div>
            </div>

            <div class="bg-white px-6 pb-6 pt-3 rounded-b-lg">

                <div class="my-5"></div>
                <template v-if="isLoading">
                    <div class="flex items-center justify-center w-full h-full">
                        <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">

                            <svg fill='none' class="w-6 h-6 animate-spin" viewBox="0 0 32 32"
                                xmlns='http://www.w3.org/2000/svg'>
                                <path clip-rule='evenodd'
                                    d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
                                    fill='currentColor' fill-rule='evenodd' />
                            </svg>


                            <div>En cours d'envoi ...</div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <template v-if="!successMessage">
                        <form class="text-left" @submit.prevent="submitForm">
                            <div class="mb-5">
                                <label for="email" class="mb-3 block text-sm font-medium text-[#07074D]">
                                    E-mail
                                </label>
                                <input v-model="form.email" required type="email" name="email" id="email"
                                    placeholder="pierre@gmail.com"
                                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-3 text-sm font-medium text-[#6B7280] outline-none focus:border-linen focus:shadow-md" />
                            </div>
                            <div class="mb-5">
                                <label for="phone" class="mb-3 block text-sm font-medium text-[#07074D]">
                                    Téléphone
                                </label>
                                <input v-model="form.phone" required type="phone" name="phone" id="phone"
                                    placeholder="01 23 45 67 89"
                                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-3 text-sm font-medium text-[#6B7280] outline-none focus:border-linen focus:shadow-md" />
                            </div>
                            <div class="mb-5">
                                <label for="date" class="mb-3 block text-sm font-medium text-[#07074D]">
                                    Date de l'événement
                                </label>
                                <input v-model="form.eventDate" required type="date" name="date" id="date"
                                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-2 px-3 text-sm font-medium text-[#6B7280] outline-none focus:border-linen focus:shadow-md" />
                            </div>
                            <Button xs w-full textButton="Envoyez" dark></Button>
                        </form>
                    </template>
                    <template v-else>
                        <p>{{ successMessage }}</p>
                    </template>
                </template>
            </div>
        </section>
    </Transition>

    <section v-if="!showPopup" class="fixed bottom-10 right-5 z-40 shadow-2xl rounded-lg max-w-xs">
        <button type="button" @click="toggleExpressDemande"
            class="bg-amber-400 text-sm sm:text-base py-2 sm:py-4 px-3 sm:px-6 rounded-lg text-white flex gap-2 w-full text-center items-center justify-center hover:scale-110 transition-all duration-150">
            <fa icon="paper-plane"></fa>
            <p class="font-bold">Demande express</p>
        </button>
    </section>


</template>

<script>
import TitleH2 from '@/Components/Typography/Headings/TitleH2.vue';
import Button from '@/Components/Buttons/Button.vue';
import ReponseExpress from '@/Components/Section/ReponseExpress.vue';

import axios from 'axios';

export default {
    name: 'HomeView',
    components: {
        TitleH2,
        Button,
        ReponseExpress,
    },
    data() {
        return {
            showPopup: false,
            form: {
                email: '',
                phone: '',
                eventDate: '',
                message: 'Nous sommes heureux de vous avoir trouvé et nous aimerions avoir plus d\'informations concernant vos services. Merci d\'avance !'
            },
            successMessage: '',
            isLoading: false
        };
    },
    methods: {
        toggleExpressDemande() {
            this.showPopup = !this.showPopup;
        },
        async submitForm() {
            this.isLoading = true;
            try {
                const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/send`, this.form);
                if (response.status === 200) {
                    this.successMessage = 'Votre message a été envoyé avec succès.';
                    this.form = { email: '', phone: '', eventDate: '', message: '' };
                    console.log('a');
                    this.$router.push('/success');
                }
            } catch (error) {
                console.error('Erreur lors de l\'envoi du formulaire:', error);
            } finally {
                this.isLoading = false;
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.showPopup = true;
        }, 15000);
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
