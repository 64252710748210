<template>
    <p class="text-normal font-inter text-olive leading-normal">
        <slot></slot>
    </p>
</template>

<script>
export default {
    name: 'paragraph',
};
</script>

<style scoped>
/* Les styles spécifiques peuvent être ajoutés ici si nécessaire */
</style>
