<template>
    <swiper :spaceBetween="20" :autoplay="{ delay: 2500 }" :slidesPerView="slidesPerView" :navigation="false"
        :modules="modules" :loop="true" :speed="2000">
        <swiper-slide v-for="(imageSrc, index) in images" :key="index" class="w-full h-full">
            <img class="h-full w-full object-cover" :src="imageSrc">
        </swiper-slide>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        images: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const modules = [Autoplay, Pagination, Navigation];
        let slidesPerView = 2; // Par défaut, 2 slides per view

        // Vérification si l'utilisateur est sur mobile
        if (window.innerWidth <= 768) {
            slidesPerView = 1; // Si mobile, 1 slide per view
        }

        return {
            modules,
            slidesPerView,
        };
    },
};
</script>
